import Overlay from 'ol/Overlay';
import { getArea, getLength } from 'ol/sphere';
import { Coordinate } from 'ol/coordinate';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import MikeVisualizerStore from '../../store/MikeVisualizerStore';
import MikeVisualizer2DMapUtil from '../MikeVisualizer2DMapUtil';

const { _getEpsgString } = MikeVisualizer2DMapUtil;
const { getState, setState } = MikeVisualizerStore;

/**
 * Utilities for map measurements (line, area) for i.e. formatting, tooltips, etc.
 *
 * @module MikeVisualize2DMeasureUtils
 * @version 1.0.0
 */

/**
 * Creates a help tooltip, replacing any previous ones.
 *
 * @protected
 */
const _createHelpTooltip = async () => {
  const {
    measureHelpTooltipElement: previousHelpTooltipElement,
    measureHelpTooltipOverlay: previousHelpTooltipOverlay,
  } = getState();
  const { measureMap } = getState();

  if (measureMap) {
    if (previousHelpTooltipElement) {
      const parentNode = previousHelpTooltipElement.parentNode;
      if (parentNode) {
        parentNode.removeChild(previousHelpTooltipElement);
      }
    }

    if (previousHelpTooltipOverlay) {
      measureMap.removeOverlay(previousHelpTooltipOverlay);
    }

    const measureHelpTooltipElement = document.createElement('div');
    measureHelpTooltipElement.className = 'ol-tooltip hidden';

    const measureHelpTooltipOverlay = new Overlay({
      element: measureHelpTooltipElement,
      offset: [15, 0],
      positioning: 'center-left',
    });

    measureMap.addOverlay(measureHelpTooltipOverlay);

    setState({ measureHelpTooltipElement : measureHelpTooltipElement as any, measureHelpTooltipOverlay });

    return { measureHelpTooltipElement, measureHelpTooltipOverlay };
  }

  return {};
};


const _setMeasurementUnit = (unit: string) => {
setState({measureUnit: unit})
}

/**
 * Shows the help tooltip.
 *
 * @protected
 */
const _showHelpTooltip = () => {
  const { measureHelpTooltipElement } = getState();
  if (measureHelpTooltipElement) {
    measureHelpTooltipElement.classList.remove('hidden');
  }
};

/**
 * Hides the help tooltip
 *
 * @protected
 */
const _hideHelpTooltip = () => {
  const { measureHelpTooltipElement } = getState();
  if (measureHelpTooltipElement) {
    measureHelpTooltipElement.classList.add('hidden');
  }
};

/**
 * Updates the help tooltip.
 *
 * @param message
 * @param position
 *
 * @protected
 */
const _updateHelpTooltip = (message?: string, position?: Coordinate) => {
  const { measureHelpTooltipElement, measureHelpTooltipOverlay } = getState();

  self._updateTooltip(measureHelpTooltipElement, measureHelpTooltipOverlay, message, position);
};

/**
 * Destroys the help tooltip, clearing state.
 *
 * @protected
 */
const _destroyHelpTooltip = async () => {
  const { measureHelpTooltipElement, measureHelpTooltipOverlay, measureMap } = getState();

  if (measureMap) {
    if (measureHelpTooltipElement) {
      const parentNode = measureHelpTooltipElement.parentNode;

      if (parentNode) {
        parentNode.removeChild(measureHelpTooltipElement);
      }
    }

    if (measureHelpTooltipOverlay) {
      measureMap.removeOverlay(measureHelpTooltipOverlay);
    }

    setState({
      measureHelpTooltipElement: undefined,
      measureHelpTooltipOverlay: undefined,
    });
  }
};

/**
 * Creates a measure tooltip, replacing any previous ones.
 *
 * @protected
 */
const _createMeasureTooltip = async () => {
  const {
    measureTooltipElement: previousTooltipElement,
    measureTooltipOverlay: previousTooltipOverlay,
  } = getState();
  const { measureMap } = getState();

  if (measureMap) {
    if (previousTooltipElement) {
      const parentNode = previousTooltipElement.parentNode;

      if (parentNode) {
        parentNode.removeChild(previousTooltipElement);
      }
    }

    if (previousTooltipOverlay) {
      measureMap.removeOverlay(previousTooltipOverlay);
    }

    const measureTooltipElement = document.createElement('div');
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';

    const measureTooltipOverlay = new Overlay({
      element: measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center',
    });
    measureMap.addOverlay(measureTooltipOverlay);

    setState({ measureTooltipElement : measureTooltipElement as any, measureTooltipOverlay });

    return {
      measureTooltipElement,
      measureTooltipOverlay,
    };
  }
  return {};
};

/**
 * Shows the measurement tooltip.
 *
 * @protected
 */
const _showMeasureTooltip = () => {
  const { measureTooltipElement } = getState();

  if (measureTooltipElement) {
    measureTooltipElement.classList.remove('hidden');
  }
};

/**
 * Hides the measurement tooltip.
 *
 * @protected
 */
const _hideMeasureTooltip = () => {
  const { measureTooltipElement } = getState();

  if (measureTooltipElement) {
    measureTooltipElement.classList.add('hidden');
  }
};

/**
 * Update the measure tooltip.
 *
 * @protected
 */
const _updateMeasureTooltip = (message?: string, position?: Coordinate) => {
  const { measureTooltipElement, measureTooltipOverlay } = getState();

  self._updateTooltip(measureTooltipElement, measureTooltipOverlay, message, position);
};

/**
 * Creates an instance of a measure tooltip that stays next to a measurement.
 * This is typically used when measurement drawing is finalized, to display the formatted measurement on the drawing.
 *
 * @param message
 * @param position
 *
 * @protected
 */
const _createStaticMeasureTooltip = async (message: string, position: Coordinate) => {
  const { measureMap } = getState();

  if (measureMap) {
    const staticTooltipElement = document.createElement('div');
    staticTooltipElement.className = 'ol-tooltip ol-tooltip-static';
    staticTooltipElement.innerHTML = message;

    const staticTooltipOverlay = new Overlay({
      element: staticTooltipElement,
      offset: [0, -7],
      positioning: 'bottom-center',
      position,
    });
    measureMap.addOverlay(staticTooltipOverlay);
  }
};

/**
 * Destroys the measure tooltip, clearing state.
 *
 * @protected
 */
const _destroyMeasureTooltip = async () => {
  const { measureMap } = getState();

  if (measureMap) {
    const { measureTooltipElement, measureTooltipOverlay } = getState();

    if (measureTooltipElement) {
      const parentNode = measureTooltipElement.parentNode;

      if (parentNode) {
        parentNode.removeChild(measureTooltipElement);
      }
    }

    if (measureTooltipOverlay) {
      measureMap.removeOverlay(measureTooltipOverlay);
    }

    setState({
      measureTooltipElement: undefined,
      measureTooltipOverlay: undefined,
    });
  }
};

/**
 * Generically updates a tooltip
 *
 * @param tooltipElement
 * @param tooltipOverlay
 * @param message
 * @param position
 */
const _updateTooltip = (
  tooltipElement?: HTMLElement,
  tooltipOverlay?: Overlay,
  message?: string,
  position?: Coordinate
) => {
  if (tooltipElement && message) {
    tooltipElement.innerHTML = message;
  }

  if (tooltipOverlay && position) {
    tooltipOverlay.setPosition(position);
  }
};

/**
 * Calculates the length of a line, using a sphere defined by the current viewer projection.
 *
 * @param line
 *
 * @protected
 */
const _calculateLength = (line: LineString): number => {
  const { epsgCode } = getState();
  return getLength(line, { projection: _getEpsgString(epsgCode) });
};

/**
 * Calculates the area of a polygon, using a sphere defined by the current viewer projection.
 *
 * @param polygon *
 *
 * @protected
 */
const _calculateArea = (polygon: Polygon): number => {
  const { epsgCode } = getState();
  return getArea(polygon, { projection: _getEpsgString(epsgCode) });
};

/**
 * Get the formatted length of a line in meters or km if > 100m.
 *
 * @param line
 */
const getFormattedLength = (line: LineString): string => {
  const {measureUnit} = getState();
  const length = self._calculateLength(line);
  if (length > 100) {
    const distanceInKm = Math.round((length / 1000) * 100) / 100;
     if( measureUnit === "english"){
       const distanceInMi = distanceInKm * 0.6214
      return `${distanceInMi} mi`;
    }
    return `${distanceInKm} Km`;
  }
  const distanceInM = Math.round(length * 100) / 100;
   if( measureUnit === "english"){
     const distanceInft = distanceInM * 3.2808
      return `${distanceInft} ft`;
    }
     return `${distanceInM} m`;
};

/**
 * Get the formatted area of a polygon in square meters or square km if > 10000m.
 *
 * @param polygon
 */
const getFormattedArea = (polygon: Polygon): string => {
  const {measureUnit} = getState();
  const area = self._calculateArea(polygon);
  if (area > 10000) {
    const areaInSqkm = Math.round((area / 1000000) * 100) / 100;
    if(measureUnit === "english"){
      return `${areaInSqkm * 0.6214} mi<sup>2</sup>`;
    }
    return `${areaInSqkm} Km<sup>2</sup>`;
  }
  const areaInSqm = Math.round(area * 100) / 100;
  if(measureUnit === "english"){
      return `${areaInSqm*3.2808} ft<sup>2</sup>`;
    }
  return `${areaInSqm} m<sup>2</sup>`;
};

const self = {
  _createHelpTooltip,
  _showHelpTooltip,
  _hideHelpTooltip,
  _updateHelpTooltip,
  _createMeasureTooltip,
  _showMeasureTooltip,
  _hideMeasureTooltip,
  _destroyHelpTooltip,
  _updateMeasureTooltip,
  _createStaticMeasureTooltip,
  _destroyMeasureTooltip,
  _updateTooltip,

_setMeasurementUnit,
  _calculateLength,
  _calculateArea,
  getFormattedLength,
  getFormattedArea,
};

export default self;
