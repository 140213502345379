/** @jsxImportSource @emotion/react */
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';
import { t } from '../../translations/i18n';
import { Feature, FeatureCollection } from 'geojson';
import { Typography } from '@mui/material';
import { fetchLayer, showLayer } from '../../shared/layers/layer-utils';
import { ELEMENT_CATEGORIES } from '../../shared/panels/mesh-panel-constants';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';
import { EWorkspaceActionType } from '../../store/actions/WorkspaceActionType';
import { useParams } from 'react-router-dom';
import EditModes, { EDITMODE_IDS, EEditModeIds } from '../../shared/edit-modes/edit-modes';
import MmgSelectForEditingModes, { ESelectForEditingModeIds } from '../../shared/edit-modes/select-for-editing-modes';
import {
  clearFeaturesForEditing,
  createGeometry,
  getFeaturesForEditing,
  setEditMode,
  updateGeometryData,
} from '../../store/actions/editingItems';
import { EItemType } from '../../models/IOperationDescriptions';
import { MmgKeyboardEditHelp } from '../../shared/edit-modes/keyboard-edit-help';
import { EDIT_LAYER_ID } from '../../store/reducers/EditReducer';
import { CONFIRM_PANEL_IDS } from '../../shared/confirm-containers/confirm-constants';
import { MmgGroup } from '../../shared/groups/group';
import { EGeometryItemTypes, GEOMETRY_ITEM_TYPES } from '../../models/IGeometries';
import {
  ContentBottomContainerStyle,
  ContentBottomDescriptionStyle,
  PanelBottomActionsStyle,
  PanelBottomContainerStyle,
  PanelBottomDescriptionStyle,
  buttonStyle,
  descriptionWithHelpStyle,
  geometryDrawingPaddingStyle,
  instructionWithHelpStyle,
  outerPaddingStyle,
} from '../../shared/edit-modes/editStyles';
/* import { MmgConnectedSimplifyOperation } from '../../shared/operations/simplify-operation';
import { MmgConnectedDissolveOperation } from '../../shared/operations/dissolve-operation'; */
import MmgPolygonDrawTools from './polygon-draw-tools';
/* import { MmgConnectedClipOperation } from '../../shared/operations/clip-operation';
import { MmgConnectedSplitOperation } from '../../shared/operations/split-operation'; */
import { previousEditsToPatchFeatures } from '../../workspaces/sagas/meshEditHelpers';
import MikeVisualizer from '../../MikeVisualizer';
import { IGlobalState } from '../../store/reducers';
import { MikeStickyPanelHeaderContainer } from '../../shared-components/mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeStickyPanelContent from '../../shared-components/mike-sticky-panel/MikeStickyPanelContent';
import MikeButton from '../../shared-components/mike-button';
import MikeStickyPanel from '../../shared-components/mike-sticky-panel';
import MikeVisualizer2DDrawCore from '../../MikeVisualizer/lib/2d/draw/MikeVisualizer2DDrawCore';
import MikeVisualizer2DDataCore from '../../MikeVisualizer/lib/2d/data/MikeVisualizer2DDataCore';
import { MmgConnectedSimplifyOperation } from '../../shared/operations/simplify-operation';

const {
  onDrawnDataUpdated,
  onDrawingInProgressChanged,
  clearDrawnVectorLayerData,
  disable2DPolygonSelection,
  delete2DData,
} = MikeVisualizer;

/**
 * @name MmgConnectedGeometryConfirmEditData Allows updating (confirming) a geometry drawing.
 * @summary It can either update or cancel a drawing of an geometry variable.
 *
 */
export function MmgConnectedGeometryConfirmEditData() {
  const { workspaceId } = useParams();
  const activeConfirmPanelId = useSelector((state: IGlobalState) => state.ConfirmPanelReducer.activeConfirmPanelId);
  const loadedData: Array<string> = useSelector((state: IGlobalState) => state.WorkspaceDataReducer.loadedData);
  const { geometryEditTargetId } = useSelector((state: IGlobalState) => state.GeometryMapToolReducer);

  const {
    featuresForEditing,
    loadingFeaturesForEditing,
    previousEdits,
    editsToApply,
    editMode,
    selectionMode,
    geometryEditItemType,
  } = useSelector((state: IGlobalState) => state.EditReducer);

  const { updatingGeometryData } = useSelector((state: IGlobalState) => state.WorkspaceGeometryReducer);

  const createNewGeometry = useMemo(
    () => {
      return activeConfirmPanelId && activeConfirmPanelId === CONFIRM_PANEL_IDS.GEOMETRY_DRAW_NEW;
    },
    [activeConfirmPanelId],
  );

  const [drawingInProgress, setDrawingInProgress] = useState(false);

  const hasInputError = false;

  useEffect(() => {
    const drawUpdateCallback = (newFeatureCollection: FeatureCollection<any, any>) => {
      if (newFeatureCollection.features && newFeatureCollection.features.length > 0) {
        store.dispatch({ type: EMapToolActionType.VALIDATE_CHANGED_VARIABLE_POINTS, data: newFeatureCollection });
      }
    };

    const unsubscribers = [];
    const unsubscribeOnDrawnDataUpdated = onDrawnDataUpdated(drawUpdateCallback);
    const unsubscribeOnDrawingInProgressChanged = onDrawingInProgressChanged(drawingProgressChangedCallback);
    unsubscribers.push(unsubscribeOnDrawingInProgressChanged, unsubscribeOnDrawnDataUpdated);

    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
      // on unmount we reset to default tools, cancel drawing and remove any drawn items.
      store.dispatch({ type: EMapToolActionType.RESET_ALLOWED_TOOLS });
      store.dispatch({ type: EMapToolActionType.GEOMETRY_UNLOAD });
      store.dispatch({ type: EMapToolActionType.DISABLE_ALL_DRAWING_TOOLS });
      store.dispatch(clearFeaturesForEditing());
      delete2DData(EDIT_LAYER_ID);
      store.dispatch({
        type: EMapToolActionType.RESET_ALLOWED_TOOLS,
      });
      store.dispatch({ type: 'clientOperation/EXIT_ACTIVE_TOOL' });
    };
  }, []);

  const { isPoint, isPolygon } = useMemo(
    () => {
      return {
        isPolygon:
          geometryEditItemType &&
          (geometryEditItemType === GEOMETRY_ITEM_TYPES.POLYGON ||
            geometryEditItemType === GEOMETRY_ITEM_TYPES.MULTI_POLYGON),
        isPoint:
          geometryEditItemType &&
          (geometryEditItemType === GEOMETRY_ITEM_TYPES.POINT ||
            geometryEditItemType === GEOMETRY_ITEM_TYPES.MULTI_POINT),
      };
    },
    [geometryEditItemType],
  );

  useEffect(
    () => {
      if (createNewGeometry) {
        store.dispatch(setEditMode(EEditModeIds.EDIT_MODE_ADD, geometryEditItemType, EItemType.GEOMETRY));
        store.dispatch({ type: EMapToolActionType.CLEAR_OPEN_SUBMENU });
      } else {
        const initializeOpenLayersMapsAndeditMode = async () => {
          clearDrawnVectorLayerData();
          // These open layers maps are required for drawing the selection geometry
          const { _getOrSetupDrawMap } = MikeVisualizer2DDrawCore;
          const { _getOrSetupOpenLayersDataMap } = MikeVisualizer2DDataCore;
          await _getOrSetupDrawMap();
          await _getOrSetupOpenLayersDataMap();
          store.dispatch({
            type: EMapToolActionType.SET_SELECTION_MODE,
            data: ESelectForEditingModeIds.SELECT_BY_RECTANGLE,
          });
          store.dispatch(setEditMode(EEditModeIds.EDIT_MODE_MODIFY, geometryEditItemType, EItemType.GEOMETRY));
        };

        initializeOpenLayersMapsAndeditMode();
      }
    },
    [createNewGeometry, geometryEditItemType],
  );

  const selectedFeaturesCount = useMemo(
    () => {
      return featuresForEditing && featuresForEditing.features ? featuresForEditing.features.length : 0;     
    },
    [featuresForEditing],
  );

  const shouldDisableConfirmButton = useMemo(
    () => {
      return previousEdits.length === 0 || updatingGeometryData || drawingInProgress || hasInputError;
    },
    [previousEdits.length, updatingGeometryData, drawingInProgress, hasInputError],
  );

  useEffect(() => {
    if (selectedFeaturesCount === 0){
      store.dispatch({ type: 'clientOperation/EXIT_ACTIVE_TOOL' });
    }    
  }, [selectedFeaturesCount])

  /**
   * Confirms drawing and updates variable from the drawn items.
   */
  const confirmDrawing = useCallback(
    () => {
      if (createNewGeometry) {
        store.dispatch(createGeometry(workspaceId, geometryEditTargetId, previousEditsToPatchFeatures(previousEdits)));
      } else {
        store.dispatch(
          updateGeometryData(workspaceId, geometryEditTargetId, previousEditsToPatchFeatures(previousEdits)),
        );
      }
    },
    [createNewGeometry, previousEdits, geometryEditTargetId, workspaceId],
  );

  /**
   * Exits current panel - further cleanups are done in useEffect
   */
  const cancelDrawing = () => {
    store.dispatch({ type: 'clientOperation/EXIT_ACTIVE_TOOL' });
    store.dispatch({ type: EWorkspaceActionType.EXIT_ACTIVE_PANEL });
  };

  /**
   * Callback for when drawing in progress changes.
   *
   * @param inProgress
   */
  const drawingProgressChangedCallback = (inProgress: boolean) => {
    setDrawingInProgress(inProgress);
  };

  useEffect(
    () => {
      if (createNewGeometry || !geometryEditTargetId) {
        return;
      }
      if (!loadedData.includes(geometryEditTargetId)) {
        fetchLayer(geometryEditTargetId, ELEMENT_CATEGORIES.GEOMETRY);
      } else {
        showLayer(ELEMENT_CATEGORIES.GEOMETRY, geometryEditTargetId, false);
      }
    },
    [createNewGeometry, loadedData, geometryEditTargetId],
  );

  const handleChangeEditMode = useCallback(
    (mode: EEditModeIds) => {
      clearDrawnVectorLayerData();
      if (mode !== EEditModeIds.EDIT_MODE_ADD) {
        store.dispatch({
          type: EMapToolActionType.SET_SELECTION_MODE,
          data: ESelectForEditingModeIds.SELECT_BY_RECTANGLE,
        });
      }
      store.dispatch(setEditMode(mode, geometryEditItemType, EItemType.GEOMETRY));
    },
    [geometryEditItemType],
  );

  const handleChangeSelectForEditingMode = (mode: ESelectForEditingModeIds) => {
    store.dispatch({ type: EMapToolActionType.SET_SELECTION_MODE, data: mode });
  };

  const handleSelectionGeometryChanged = useCallback(
    async (feature: Feature<any>) => {
      store.dispatch({ type: EMapToolActionType.SET_SELECTION_MODE, data: null });
      disable2DPolygonSelection();
      if (selectionMode) {
        clearDrawnVectorLayerData();
        // Gets data corresponding to the target variable and selection geometry and sets it as drawn geojson.
        store.dispatch(
          getFeaturesForEditing(
            workspaceId,
            geometryEditTargetId,
            EItemType.GEOMETRY,
            { type: 'FeatureCollection', features: [feature] },
            previousEdits,
            geometryEditItemType,
          ),
        );
      }
    },
    [previousEdits, selectionMode, geometryEditTargetId, workspaceId, geometryEditItemType],
  );

  const handleUndo = useCallback(
    () => {
      store.dispatch({ type: EMapToolActionType.UNDO_FEATURES_FOR_EDITING, data: geometryEditItemType });
    },
    [geometryEditItemType],
  );

  const handleApply = useCallback(
    () => {
      switch (editMode) {
        case EEditModeIds.EDIT_MODE_ATTRIBUTION: {
          store.dispatch({ type: EMapToolActionType.STORE_FEATURES_FOR_EDITING, data: geometryEditItemType });
          store.dispatch({ type: 'clientOperation/EXIT_ACTIVE_TOOL' });
          break;
        }
        case EEditModeIds.EDIT_MODE_ADD: {
          store.dispatch({ type: EMapToolActionType.ADD_DRAWN_FEATURES, data: geometryEditItemType });
          break;
        }
        default: {
          store.dispatch({ type: EMapToolActionType.STORE_FEATURES_FOR_EDITING, data: geometryEditItemType });
          break;
        }
      }
    },
    [editMode, geometryEditItemType],
  );

  const getApplyTitle = useCallback(
    () => {
      switch (editMode) {
        case EEditModeIds.EDIT_MODE_MODIFY:
          return t('APPLY_CHANGES_AND_CREATE_NEW_SELECTION');
        case EEditModeIds.EDIT_MODE_ATTRIBUTION:
          return t('APPLY_CHANGES_AND_CREATE_NEW_SELECTION');
        case EEditModeIds.EDIT_MODE_ADD:
          return t('APPLY_CHANGES_AND_CREATE_NEW_SELECTION');
        case EEditModeIds.EDIT_MODE_DELETE:
          return t('APPLY_CHANGES_AND_CREATE_NEW_SELECTION');
      }
    },
    [editMode],
  );

  const getApplyGroupTitle = useCallback(
    () => {
      const getAddLabel = (type: EGeometryItemTypes) => {
        switch (type) {
          case EGeometryItemTypes.POINT:
          case EGeometryItemTypes.MULTI_POINT: {
            return t('CHANGE_GEOMETRY_POINTS_GROUP_ADD');
          }
          case EGeometryItemTypes.LINE_STRING:
          case EGeometryItemTypes.MULTI_LINE_STRING: {
            return t('CHANGE_GEOMETRY_POLYLINES_GROUP_ADD');
          }
          case EGeometryItemTypes.POLYGON:
          case EGeometryItemTypes.MULTI_POLYGON: {
            return t('CHANGE_GEOMETRY_POLYGONS_GROUP_ADD');
          }
        }
      };
      const getDeleteLabel = (type: EGeometryItemTypes) => {
        switch (type) {
          case EGeometryItemTypes.POINT:
          case EGeometryItemTypes.MULTI_POINT: {
            return t('CHANGE_GEOMETRY_POINTS_GROUP_DELETE');
          }
          case EGeometryItemTypes.LINE_STRING:
          case EGeometryItemTypes.MULTI_LINE_STRING: {
            return t('CHANGE_GEOMETRY_POLYLINES_GROUP_DELETE');
          }
          case EGeometryItemTypes.POLYGON:
          case EGeometryItemTypes.MULTI_POLYGON: {
            return t('CHANGE_GEOMETRY_POLYGONS_GROUP_DELETE');
          }
        }
      };
      switch (editMode) {
        case EEditModeIds.EDIT_MODE_MODIFY:
          return t('CHANGE_GEOMETRY_GROUP_MODIFY');
        case EEditModeIds.EDIT_MODE_ATTRIBUTION:
          return t('CHANGE_GEOMETRY_GROUP_GEOPROCESSING');
        case EEditModeIds.EDIT_MODE_ADD:
          return getAddLabel(geometryEditItemType);
        case EEditModeIds.EDIT_MODE_DELETE:
          return getDeleteLabel(geometryEditItemType);
      }
    },
    [editMode, geometryEditItemType],
  );

  const disableApplyButton = useCallback(
    () => {
      if (editMode === EEditModeIds.EDIT_MODE_DELETE) {
        return selectedFeaturesCount === 0;
      }
      return !editsToApply;
    },
    [editMode, editsToApply, selectedFeaturesCount],
  );

  const getModifyInstruction = (geometryType: EGeometryItemTypes) => {
    switch (geometryType) {
      case EGeometryItemTypes.POINT:
      case EGeometryItemTypes.MULTI_POINT: {
        return t('MODIFY_GEOMETRY_POINTS');
      }
      case EGeometryItemTypes.LINE_STRING:
      case EGeometryItemTypes.MULTI_LINE_STRING: {
        return t('MODIFY_GEOMETRY_POLYLINES');
      }
      case EGeometryItemTypes.POLYGON:
      case EGeometryItemTypes.MULTI_POLYGON: {
        return t('MODIFY_GEOMETRY_POLYGONS');
      }
    }
  };

  const operationStatusChanged = (fc: FeatureCollection<any, any>, mixedEditTypeSet: boolean) => {
    fc &&
      store.dispatch({
        type: EMapToolActionType.SET_GEOPROCESSING_EDITS_TO_APPLY,
        data: { featuresForEditing: fc, mixedEditTypeAlreadySet: mixedEditTypeSet },
      });
  };

  const getEditComponents = useCallback(
    () => {
      if (editMode === EEditModeIds.EDIT_MODE_MODIFY) {
        return (
          <div css={instructionWithHelpStyle}>
            <Typography variant="body2" css={outerPaddingStyle}>
              {getModifyInstruction(geometryEditItemType)}
            </Typography>
            {!isPoint && (
              <MmgKeyboardEditHelp
                editMode={editMode}
                itemType={EItemType.GEOMETRY}
                geometryType={geometryEditItemType}
              />
            )}
          </div>
        );
      } else if (editMode === EEditModeIds.EDIT_MODE_ATTRIBUTION) {
        return (
          <div>
            {isPolygon && (
              <MmgConnectedSimplifyOperation
                setOperationDone={operationStatusChanged}
                disabled={selectedFeaturesCount === 0}
              />
            )}
          </div>
        )
      }
        /* return (
          <div>
            {!isPoint && (
              <MmgConnectedSimplifyOperation
                setOperationDone={operationStatusChanged}
                disabled={selectedFeaturesCount === 0}
              />
            )}
            {isPolygon && (
              <MmgConnectedDissolveOperation
                setOperationDone={operationStatusChanged}
                disabled={selectedFeaturesCount === 0}
              />
            )}
            {!isPoint && (
              <MmgConnectedClipOperation
                setOperationDone={operationStatusChanged}
                disabled={selectedFeaturesCount === 0}
              />
            )}
            {isLatLong &&
              !isPoint && (
                <MmgConnectedSplitOperation
                  setOperationDone={operationStatusChanged}
                  disabled={selectedFeaturesCount === 0}
                />
              )}
          </div>
        );
      } else {
        return null;
      } */
    },
    [editMode, geometryEditItemType, isPoint, isPolygon, selectedFeaturesCount],
  );

  const { addLabel, addGroupLabel, selectGroupLabel } = useMemo(
    () => {
      if (isPoint) {
        return {
          addLabel: t('ADD_GEOMETRY_POINTS'),
          addGroupLabel: t('ADD_GEOMETRY_POINTS_GROUP'),
          selectGroupLabel: t('SELECT_GEOMTRY_POINTS_GROUP'),
        };
      } else if (isPolygon) {
        return {
          addLabel: t('ADD_GEOMETRY_POLYGONS'),
          addGroupLabel: t('ADD_GEOMETRY_POLYGONS_GROUP'),
          selectGroupLabel: t('SELECT_GEOMTRY_POLYGONS_GROUP'),
        };
      } else {
        return {
          addLabel: t('ADD_GEOMETRY_POLYLINES'),
          addGroupLabel: t('ADD_GEOMETRY_POLYLINES_GROUP'),
          selectGroupLabel: t('SELECT_GEOMTRY_POLYLINES_GROUP'),
        };
      }
    },
    [isPoint, isPolygon],
  );

  return (
    <MikeStickyPanel>
      <MikeStickyPanelHeaderContainer>
        <Typography css={outerPaddingStyle} variant="h4">
          {createNewGeometry ? t('DRAW_CONFIRMATION_TITLE') : t('GEOMETRY_EDIT_CONFIRMATION_TITLE')}
        </Typography>
        <EditModes
          onEditModeChanged={handleChangeEditMode}
          editMode={editMode as any}
          itemType={EItemType.GEOMETRY}
          createNew={createNewGeometry}
          disabled={loadingFeaturesForEditing}
          geometryType={geometryEditItemType}
        />
      </MikeStickyPanelHeaderContainer>
      <MikeStickyPanelContent>
        {editMode === EDITMODE_IDS.EDIT_MODE_ADD ? (
          <MmgGroup groupName={addGroupLabel}>
            <div css={descriptionWithHelpStyle}>
              <Typography variant="body2">{addLabel}</Typography>
              <MmgKeyboardEditHelp
                editMode={EDITMODE_IDS.EDIT_MODE_ADD}
                itemType={EItemType.GEOMETRY}
                geometryType={geometryEditItemType}
              />
            </div>
            {isPolygon && (
              <div css={geometryDrawingPaddingStyle}>
                <MmgPolygonDrawTools />
              </div>
            )}
          </MmgGroup>
        ) : (
          <MmgGroup groupName={selectGroupLabel}>
            <div css={outerPaddingStyle}>
              <MmgSelectForEditingModes
                onSelectForEditingModeChanged={handleChangeSelectForEditingMode}
                selectionMode={selectionMode}
                onSelectionGeometryChanged={handleSelectionGeometryChanged}
                disabled={loadingFeaturesForEditing}
                itemType={EItemType.GEOMETRY}
              />
            </div>
          </MmgGroup>
        )}

        <MmgGroup groupName={getApplyGroupTitle()}>
          <div css={ContentBottomContainerStyle}>
            {getEditComponents()}

            <Typography css={ContentBottomDescriptionStyle} variant="body2">
              {getApplyTitle()}
            </Typography>
            <div css={PanelBottomActionsStyle}>
              <MikeButton
                css={buttonStyle}
                variant="outlined" color="secondary"
                disabled={previousEdits.length === 0}
                onClick={handleUndo}
              >
                {t('MESH_NODE_EDIT_UNDO')}
              </MikeButton>
              <MikeButton
                css={buttonStyle}
                variant="outlined" color="secondary"
                onClick={handleApply}
                disabled={disableApplyButton()}
              >
                {t('APPLY_CHANGES')}
              </MikeButton>
            </div>
          </div>
        </MmgGroup>
      </MikeStickyPanelContent>
      <div css={PanelBottomContainerStyle}>
        <Typography css={PanelBottomDescriptionStyle} variant="body2">
          {createNewGeometry ? t('CREATE_VARIABLE_POINTS_SAVE') : t('FINISH_EDIT_SESSION')}
        </Typography>
        <div css={PanelBottomActionsStyle}>
          <MikeButton variant="outlined" color="secondary" onClick={cancelDrawing} disabled={updatingGeometryData}>
            {t('CANCEL')}
          </MikeButton>
          <MikeButton variant="contained" color="secondary" disabled={shouldDisableConfirmButton} onClick={confirmDrawing} active={updatingGeometryData}>
            {createNewGeometry ? t('CONFIRM_CREAING_VARIABLE') : t('CONFIRM_EDITING_VARIABLE')}
          </MikeButton>
        </div>
      </div>
    </MikeStickyPanel>
  );
}
