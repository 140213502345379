/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react'
import Checkmark from '../../../icons/Checkmark.svg?react';
import Alert from '../../../icons/Alert.svg?react';
import MikeTooltip from '../../mike-tooltip';
import mikeSharedTheme from '../../../shared/styles/mikeSharedTheme';
import CircularProgress from '@mui/material/CircularProgress';
import MIKE_COLORS from '../../../shared/styles/mike-colors';

const buttonStyle = css`
  display: flex;
  align-items: center;
  padding: 0px 10px;
  min-height: 60px;
  background-color: ${mikeSharedTheme.palette.text.disabled};
  margin-top: -4px;
  width: 60px;
  cursor: pointer;
`;

const innerDivStyle = css`
  margin: auto;
`;

const spinnersCheckDivStyle = () => {  
  return css`
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background-color: ${MIKE_COLORS.GREEN_DEFAULT};
    margin: auto;
  `;
} 

const spinnersCheckSvgStyle = css`  
  path {
    fill: #fff;
    transform: translate(-8px, -8px);
  };
`;

const spinnersAlertDivStyle = css`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: ${mikeSharedTheme.palette.error.main};
  margin: auto;
`;

const spinnersAlertSvgStyle = css`
  path {
    fill: #fff;
    transform: translate(-8px, -8px);
  };
  path:first-of-type {
    fill: transparent;
    stroke: #fff;
    strokeWidth: 2px;
    strokeJoin: miter;
  };
`;

function SpinnersCheckMark() {  
  return (
    <div css={spinnersCheckDivStyle}>
      <Checkmark
        css={spinnersCheckSvgStyle}
        width={undefined} // overrules the default 40 value
        height={undefined} // overrules the default 40 value
        viewBox="-6 -6 35 35"
      />
    </div>
  );
}

function SpinnersAlert() {
  return (
    <div css={spinnersAlertDivStyle}>
      <Alert
        css={spinnersAlertSvgStyle}
        width={undefined} // overrules the default 40 value
        height={undefined} // overrules the default 40 value
        viewBox="-6.5 -5.5 37 37"
      />
    </div>
  );
}

export type SpinnersButtonIcon = 'spinner' | 'alert' | 'checkmark';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  onClick?: () => void;
  icon?: SpinnersButtonIcon;
  tooltipTitles?: IButtonTooltips;
}
export interface IButtonTooltips {
  spinner?: string;
  alert?: string;
  checkmark?: string;
}

function SpinnersButton(props: IProps) {
  const { onClick, icon = 'spinner', tooltipTitles } = props;
  const ttTitles: IButtonTooltips = {
    spinner: 'Tasks in progress',
    alert: 'Error in one or more tasks',
    checkmark: 'All tasks done',
    ...tooltipTitles,
  };
  const ttTitle = ttTitles[icon];  
  return (
    <div css={buttonStyle} onClick={onClick}>
      <div css={innerDivStyle}>
        <MikeTooltip title={ttTitle !== undefined ? ttTitle : 'Tasks in progress'} arrow mikeposition={{ top: 17, left: -8 }}>
          <>
            {icon === 'checkmark' && <SpinnersCheckMark />}
            {icon === 'alert' && <SpinnersAlert />}
            {icon === 'spinner' && <CircularProgress size={16} />}
          </>
        </MikeTooltip>
      </div>
    </div>
  );
}

export default SpinnersButton;
