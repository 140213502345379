/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { Typography } from '@mui/material';
import MIKE_COLORS from '../styles/mike-colors';
import mikeSharedTheme from '../styles/mikeSharedTheme';

const OperationContainerStyle = css`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${MIKE_COLORS.MEDIUMGREY_LIGHT};
`;

const OperationToggleStyle = css`
  border: 0;
  background: 0;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${mikeSharedTheme.spacing(1)} ${mikeSharedTheme.spacing(1)} ${mikeSharedTheme.spacing(1)} ${mikeSharedTheme.spacing(2)};

  :focus {
    outline: 0;
  }
`;

const OperationContainerButtonsStyle = css`
  display: flex;
  justify-content: space-between;
  padding: ${mikeSharedTheme.spacing(2)};
`;

type OperationContainerProps = {
  isOpen: boolean;
  operationName: string;
  onContainerToggled?: (open: boolean) => void;
  children?: React.ReactNode;
  buttons?: React.ReactNode;
  disabled?: boolean;
};

/**
 * @name MmgOperationContainer
 * @summary A generic container for operation components.
 *
 * @param props
 */
export function MmgOperationContainer(props: OperationContainerProps) {
  const { operationName, onContainerToggled, isOpen, disabled } = props;

  function toggleContainer() {
    const newState = !isOpen;

    if (onContainerToggled) {
      onContainerToggled(newState);
    }
  }

  return (
    <article css={OperationContainerStyle}>
      <button css={OperationToggleStyle} onClick={toggleContainer} disabled={disabled}>
        <Typography variant="body2">{operationName}</Typography>
        {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </button>

      {isOpen && (
        <div>
          {props.children}

          {props.buttons && <footer css={OperationContainerButtonsStyle}>{props.buttons}</footer>}
        </div>
      )}
    </article>
  );
}
