/* eslint-disable react-refresh/only-export-components */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { t } from '../../translations/i18n';
import Delete from '../../icons/Delete.svg?react';
import Edit from '../../icons/Edit.svg?react';
import Plus from '../../icons/Plus.svg?react';
import Adjust from '../../icons/Adjust.svg?react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { EItemType } from '../../models/IOperationDescriptions';
import { EGeometryItemTypes } from '../../models/IGeometries';
import mikeSharedTheme from '../styles/mikeSharedTheme';

const containerStyle = css`
  paddingBottom: ${mikeSharedTheme.spacing(2)}
`;

const hiddenTabRootStyle = {
  minWidth: 0,
  padding: 0,
  margin: 0,
  minHeight: '48px'
}

const tabsIndicatorStyle = {
  height: '3px'
}

export enum EEditModeIds {
  EDIT_MODE_MODIFY = 'EDIT_MODE_MODIFY',
  EDIT_MODE_ATTRIBUTION = 'EDIT_MODE_ATTRIBUTION',
  EDIT_MODE_ADD = 'EDIT_MODE_ADD',
  EDIT_MODE_DELETE = 'EDIT_MODE_DELETE',
}

export const EDITMODE_IDS = {
  EDIT_MODE_MODIFY: EEditModeIds.EDIT_MODE_MODIFY,
  EDIT_MODE_ADD: EEditModeIds.EDIT_MODE_ADD,
  EDIT_MODE_DELETE: EEditModeIds.EDIT_MODE_DELETE,
  EDIT_MODE_ATTRIBUTION: EEditModeIds.EDIT_MODE_ATTRIBUTION,
};

interface IProps {
  onEditModeChanged: (mode: EEditModeIds) => void;
  editMode: EEditModeIds;
  itemType: EItemType;
  createNew: boolean;
  disabled?: boolean;
  geometryType?: EGeometryItemTypes;
}

/**
 * @param props
 * @name MmgEditModes
 * @summary Allows changing node modes (modify / add / delete)
 */
const MmgEditModes = (props: IProps) => {
  const { onEditModeChanged, editMode, itemType, createNew, disabled, geometryType = EGeometryItemTypes.POINT } = props;

  const handleChangeEditMode = (_event, mode: EEditModeIds) => {
    onEditModeChanged(mode);
  };

  const { modifyLabel, attributionLabel, addLabel, deleteLabel } = useMemo(
    () => {
      const getModifyLabel = (type: EItemType) => {
        switch (type) {
          case EItemType.VARIABLE: {
            return t('VARIABLE_MOVE_POINTS');
          }
          case EItemType.GEOMETRY: {
            return t('GEOMETRY_MODIFY_VERTICES');
          }
          case EItemType.MESH: {
            return t('MESH_MOVE_NODES');
          }
        }
      };
      const getAttributionLabel = (type: EItemType) => {
        switch (type) {
          case EItemType.VARIABLE: {
            return t('VARIABLE_POINTS_ATTRIBUTION');
          }
          case EItemType.GEOMETRY: {
            return t('GEOMETRY_GEOPROCESSING');
          }
          case EItemType.MESH: {
            return t('MESH_NODES_ATTRIBUTION');
          }
        }
      };
      const getGeometryAddLabel = (geometry: EGeometryItemTypes) => {
        switch (geometry) {
          case EGeometryItemTypes.POINT:
          case EGeometryItemTypes.MULTI_POINT: {
            return t('GEOMETRY_ADD_POINTS');
          }
          case EGeometryItemTypes.LINE_STRING:
          case EGeometryItemTypes.MULTI_LINE_STRING: {
            return t('GEOMETRY_ADD_POLYLINES');
          }
          case EGeometryItemTypes.POLYGON:
          case EGeometryItemTypes.MULTI_POLYGON: {
            return t('GEOMETRY_ADD_POLYGONS');
          }
        }
      };
      const getGeometryDeleteLabel = (geometry: EGeometryItemTypes) => {
        switch (geometry) {
          case EGeometryItemTypes.POINT:
          case EGeometryItemTypes.MULTI_POINT: {
            return t('GEOMETRY_DELETE_POINTS');
          }
          case EGeometryItemTypes.LINE_STRING:
          case EGeometryItemTypes.MULTI_LINE_STRING: {
            return t('GEOMETRY_DELETE_POLYLINES');
          }
          case EGeometryItemTypes.POLYGON:
          case EGeometryItemTypes.MULTI_POLYGON: {
            return t('GEOMETRY_DELETE_POLYGONS');
          }
        }
      };
      const getAddLabel = (type: EItemType, geoType: EGeometryItemTypes) => {
        switch (type) {
          case EItemType.VARIABLE: {
            return t('VARIABLE_ADD_POINTS');
          }
          case EItemType.GEOMETRY: {
            return getGeometryAddLabel(geoType);
          }
          case EItemType.MESH: {
            return t('MESH_ADD_NODES');
          }
        }
      };
      const getDeleteLabel = (type: EItemType, geoType: EGeometryItemTypes) => {
        switch (type) {
          case EItemType.VARIABLE: {
            return t('VARIABLE_DELETE_POINTS');
          }
          case EItemType.GEOMETRY: {
            return getGeometryDeleteLabel(geoType);
          }
          case EItemType.MESH: {
            return t('MESH_DELETE_NODES');
          }
        }
      };
      return {
        modifyLabel: getModifyLabel(itemType),
        attributionLabel: getAttributionLabel(itemType),
        addLabel: getAddLabel(itemType, geometryType),
        deleteLabel: getDeleteLabel(itemType, geometryType),
      };
    },
    [itemType, geometryType],
  );

  return (
    <div css={containerStyle}>
      <Tabs
        sx={{ indicator: tabsIndicatorStyle }}
        value={editMode}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChangeEditMode}        
      >
        <Tab disabled={disabled} sx={{ root: hiddenTabRootStyle }}/>

        {!createNew && <Tab disabled={disabled} icon={<Edit />} label={modifyLabel} value={EDITMODE_IDS.EDIT_MODE_MODIFY} />}
        {!createNew /* && itemType !== EItemType.GEOMETRY */ && <Tab disabled={disabled} icon={<Adjust />} label={attributionLabel} value={EDITMODE_IDS.EDIT_MODE_ATTRIBUTION} />}
        {<Tab disabled={disabled} icon={<Plus />} label={addLabel} value={EDITMODE_IDS.EDIT_MODE_ADD} />}
        {!createNew /* && itemType !== EItemType.VARIABLE */ && <Tab disabled={disabled} icon={<Delete />} label={deleteLabel} value={EDITMODE_IDS.EDIT_MODE_DELETE} />}
      </Tabs>
    </div> 
  );
};

export default MmgEditModes;
