export const ViewerZindex = 20;
export const ToolsZindex = 40;
export const LayerHeadingZindex = 45;
export const PanelStepperZindex = 46; /* has to be > --layer-heading-zindex */
export const PanelHeaderZindex = 50;
export const MapViewerModesZindex = 50; /* has be on top of viewer tools */
export const PanelZindex = 95;
export const ColorLegendsZindex = 90;
export const TopbarDropdownZindex = 1201;

export const ViewerMapAttributionsZindex = 99999; /* always on top */

export const DefaultIconSize = '40px';

export const MenuBarHeight = '60px';

export const ToolbarWidth = '64px';
export const PanelWidth = '400px';

export const LabelFontSize = '11px';
export const TextFontSize = '14px';
export const TitleFontSize = '16px';

export const TopPadding = '24px';

export const ToolbarBoxShadow = '4px 0px 4px rgba(0, 0, 0, 0.16)';

export const MinStringLengthToShowTooltipOnSelectLayer = 25;

export const CellInfoZIndex = 90;
