import { useState, useEffect, useMemo } from 'react';
import { t } from '../../translations/i18n';
import {  FeatureCollection } from 'geojson';
import { store } from '../../store/store';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash-es';
import { MmgOperationContainer } from './operation-container';
import { OPERATION_DESCRIPTION_STYLES, OPERATION_INPUT_STYLES } from './operation-styles';
import { OPERATION_TOOL_IDS } from './operation-constants';
import { EMapToolActionType } from '../../store/actions/MapToolActionType';
import MikeVisualizer from '../../MikeVisualizer';
import { IGlobalState } from '../../store/reducers';
import GeoJSONReader from 'jsts/org/locationtech/jts/io/GeoJSONReader.js'
import GeoJSONWriter from 'jsts/org/locationtech/jts/io/GeoJSONWriter.js'
import GeometryFactory from 'jsts/org/locationtech/jts/geom/GeometryFactory.js'
import TopologyPreservingSimplifier from 'jsts/org/locationtech/jts/simplify/TopologyPreservingSimplifier'
import TextField from '@mui/material/TextField';
import { translateWithPrefixAndSuffix } from '../../translations/utils';
const { getCurrentlyDrawnGeojson, setDrawVectorLayerGeojson } = MikeVisualizer;

const emptyFeatureCollection: FeatureCollection<any, any> = {
  type: 'FeatureCollection',
  features: [],
};

type SimplifyOperationProps = {
  setOperationDone?: (fc: FeatureCollection<any, any>, mixedEditModeSet: boolean) => void;
  disabled?: boolean;
};

/**
 * @name MmgConnectedSimplifyOperation
 * @summary Gets currently drawn geojson and simplifies.
 *
 *
 * @param props
 */
export function MmgConnectedSimplifyOperation(props: SimplifyOperationProps) {
  const { setOperationDone, disabled } = props;

  const { polygonDrawingEnabled, polylineDrawingEnabled, pointDrawingEnabled } = useSelector(
    (state: IGlobalState) => state.MapToolReducer,
  );
  const activeOperationToolId = useSelector(
    (state: IGlobalState) => state.GeometryClientOperationReducer.activeOperationToolId,
  );

 const workspace = useSelector((state: IGlobalState) => state.WorkspaceReducer.workspace);
  
  const isLatLong = useMemo(
    () => {
      return workspace && workspace.isLatLon;
    },
    [workspace],
  ); 

  const isOpen = activeOperationToolId === OPERATION_TOOL_IDS.SIMPLIFY;
  const [simplificationTolerance, setSimplificationTolerance] = useState(0);
  const [initialFeatureCollection, setInitialFeatureCollection] = useState({
    ...emptyFeatureCollection,
  });

  const simplifyGeometryDebounced = debounce(
    async (tolerance: number, geojson: FeatureCollection<any, any>) => {
      try {
        const geometryFactory = new GeometryFactory();         
        const geojsonRader = new GeoJSONReader(geometryFactory);  
        const geojsonWriter = new GeoJSONWriter();
        const features = geojson.features
        const processedFeatures = features.map(function (feature) {         
          const jstsGeometry = geojsonRader.read(feature.geometry);
          // All vertices in the simplified geometry will be within distance tolerance of the original geometry. The tolerance value must be non-negative. A tolerance value of zero is effectively a no-op.
          const simplifiedGeometry = TopologyPreservingSimplifier.simplify(jstsGeometry, tolerance);         
          const simplified = geojsonWriter.write(simplifiedGeometry)
          return {...feature, geometry: simplified}
        }); 
               
      const result = {...geojson, features: processedFeatures}
        console.log(result)     
        await setDrawVectorLayerGeojson(result as FeatureCollection<any, any>);
        setOperationDone(result as FeatureCollection<any, any>, false);
      } catch (error) {
        console.log(error);
      }
    },
    1000,
    { maxWait: 1000 },
  );

  const handleInputChange = (event) => {
    const distance = event.target.value === '' ? 0 : Number(event.target.value);
    setSimplificationTolerance(distance);
    if (distance > 0) {
      simplifyGeometryDebounced(distance, initialFeatureCollection);
    }
  };

  const simplifyToggled = (open: boolean) => {
    if (open) {
      store.dispatch({
        type: 'clientOperation/SET_ACTIVE_TOOL',
        operationToolId: OPERATION_TOOL_IDS.SIMPLIFY,
      });
    } else {
      store.dispatch({ type: 'clientOperation/EXIT_ACTIVE_TOOL' });
    }
  };

  useEffect(
    () => {
      if (isOpen) {
        setInitialFeatureCollection(getCurrentlyDrawnGeojson());
        store.dispatch({ type: EMapToolActionType.DISABLE_ALL_DRAWING_TOOLS });
      } else {
        setInitialFeatureCollection(emptyFeatureCollection);
        setSimplificationTolerance(0);
      }
    },
    [isOpen],
  );

  useEffect(
    () => {
      if (polygonDrawingEnabled || polylineDrawingEnabled || pointDrawingEnabled) {
        setInitialFeatureCollection(emptyFeatureCollection);
        setSimplificationTolerance(0);
      }
    },
    [polygonDrawingEnabled, polylineDrawingEnabled, pointDrawingEnabled],
  );

  return (
    <MmgOperationContainer
      isOpen={isOpen}
      onContainerToggled={simplifyToggled}
      operationName={t('UI_SIMPLIFYOPERATION')}
      disabled={disabled}
    >
      <div css={OPERATION_DESCRIPTION_STYLES}>
        {translateWithPrefixAndSuffix('OP', 'DESCRIPTION', "SIMPLIFYOPERATIONDEFINITION", true)}
      </div>
      <div css={OPERATION_INPUT_STYLES}>       
          <TextField
            label={t('UI_SIMPLIFYOPERATION_TOLERANCE')}
            value={simplificationTolerance}
            margin="dense"
            fullWidth
            onChange={handleInputChange}
            slotProps={{input:{          
              inputProps: { step: isLatLong ? 0.001 : 1 },
              type: 'number',
            }}}
          />  
      </div>
    </MmgOperationContainer>
  );
}
