/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { TopbarDropdownZindex } from '../../shared/styles/styleConsts';

// Wrap a div with this style around your button AND your dropdown
// to align them dynamically:
export const wrapperStyle = css`
  position: relative;
  height: 55px;
  width: 60px;
  margin-top: -10px;
  z-index: ${TopbarDropdownZindex};
`;