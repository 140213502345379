/** @jsxImportSource @emotion/react */
/* eslint-disable react-refresh/only-export-components */
// Http interceptors, other setup should be done first.
import './app-setup.ts'; // NB: needs to be loaded before anything else.
import './proxies/http-interceptor.js';
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom'
import { store } from './store/store';
import { t } from './translations/i18n';
import { css, Global } from '@emotion/react';
import { ThemeProvider } from "@mui/material";
import './shared/styles/mesh-shared.css';
import { OidcConfiguration, OidcProvider } from '@axa-fr/react-oidc'
import fetchConfig from './proxies/fetchConfig';
import init from './proxies/PlatformProxy';
import mikeSharedTheme from './shared/styles/mikeSharedTheme';
import './shared/styles/mike-scrollbars.css';
import MIKE_COLORS from './MikeVisualizer/colors/mike-colors.ts';
import { ConnectedApp } from './app/App.tsx';
import OpenIdStatusPage from './shared-components/mike-login/OpenIdStatusPage.tsx';

interface IndexProps {
  openIdConfig: OidcConfiguration
}

const Index = (props: IndexProps) => {
  const { openIdConfig } = props;

  return (
    <ReduxProvider store={store}>
      <Global
        styles={css`
          body {
            background: ${MIKE_COLORS.XLIGHTGREY} !important; /* Theme incorrectly makes background grey */
            font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !important; /* Theme incorrectly sets fonts to Roboto only, which means if font load fails it will use a serif browser default - not what we want */
          }

          /* Resets for i.e. older Edge versions */
          button {
            border: 0;
            background: 0;
            padding: 0;
          }
        `}
      />
      <OidcProvider
        configuration={openIdConfig}
   /*      onSessionLost={() => {
          login('/');
        }} */
        authenticatingComponent={() => (
          <OpenIdStatusPage headline={t('LOGIN_AUTHENTICATING')} body={t('LOGIN_PLEASE_WAIT')} />
        )}
        callbackSuccessComponent={() => (
          <OpenIdStatusPage headline={t('LOGIN_AUTHENTICATING')} body={t('LOGIN_PLEASE_WAIT')} />
        )}
        authenticatingErrorComponent={() => (
          <OpenIdStatusPage
            headline={t('LOGIN_AUTHENTION_FAILED')}
            body={t('LOGIN_TRY_AGAIN')}
            showRetryButton={true}
          />
        )}
        loadingComponent={() => <OpenIdStatusPage headline={t('LOGIN_LOADING')} body={t('LOGIN_PLEASE_WAIT')} />}
      >
        <Router>
          <ThemeProvider theme={mikeSharedTheme}>
            <ConnectedApp />
          </ThemeProvider>
        </Router>
      </OidcProvider>
    </ReduxProvider>
  );
};

fetchConfig().then((config) => {
  const openIdConfig = {
    client_id: config.oAuth2.clientName,
    redirect_uri: `${window.location.origin}/authentication/callback`,
    silent_redirect_uri: `${window.location.origin}/authentication/silent-callback`,
    scope: 'openid offline_access platform_metadata_readwrite platform_iam_principal_readwrite platform_job_readwrite platform_job_execute platform_consumptionlog_readwrite platform_gis_readwrite platform_iam_principal_readwrite platform_metadata_readwrite platform_metadata_conversion platform_multidimensional_readwrite platform_raw_readwrite platform_sharing_readwrite platform_timeseries_readwrite platform_eventing_readwrite platform_iam_billing_read',  
    authority: config.oAuth2.url,
    service_worker_relative_url: '/OidcServiceWorker.js',
    service_worker_only: false,
    refresh_time_before_token_expiration_in_second: 120,
  };
  init(config.platformApiUrl);  
  navigator.serviceWorker.getRegistration().then(function (reg) {
    // On a hard refresh (shift + refresh) there's an active SW, but no controller for this tab.
    // https://stackoverflow.com/questions/51597231/register-service-worker-after-hard-refresh
    if (reg && reg.active && !navigator.serviceWorker.controller) {
      // Perform a soft reload to load everything from the SW and get
      // a consistent set of resources.
      window.location.reload();
    }
  });
  createRoot(document.getElementById('root')!).render(<Index openIdConfig={openIdConfig} />);
});

