export enum EOperationToolIds {
  SIMPLIFY = 'simplify',
  DISSOLVE = 'dissolve',
  CLIP = 'clip',
  SPLIT = 'split',
}

export const OPERATION_TOOL_IDS = {
  SIMPLIFY: EOperationToolIds.SIMPLIFY,
  DISSOLVE: EOperationToolIds.DISSOLVE,
  CLIP: EOperationToolIds.CLIP,
  SPLIT: EOperationToolIds.SPLIT,
};
