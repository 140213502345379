/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import mikeSharedTheme from '../styles/mikeSharedTheme';
import MIKE_COLORS from '../styles/mike-colors';

export const OPERATION_INPUT_STYLES = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding-left: ${mikeSharedTheme.spacing(2)};
  padding-right: ${mikeSharedTheme.spacing(2)};
`;

export const OPERATION_DESCRIPTION_STYLES = css`
  padding-left: ${mikeSharedTheme.spacing(2)};
  padding-right: ${mikeSharedTheme.spacing(2)};
  color: ${MIKE_COLORS.DARKGREY_DEFAULT};
  font-size: 12px;
`;
